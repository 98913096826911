import { template as template_a0bb0d3b4a894249819da2efa8f446eb } from "@ember/template-compiler";
const FKText = template_a0bb0d3b4a894249819da2efa8f446eb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
