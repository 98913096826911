import { template as template_e8aebec0bfd84733bc375775eec25e25 } from "@ember/template-compiler";
const FKLabel = template_e8aebec0bfd84733bc375775eec25e25(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
