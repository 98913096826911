import { template as template_0de8b54294374eec94f10c900ee02619 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0de8b54294374eec94f10c900ee02619(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
