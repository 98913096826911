import { template as template_7a5a5d80ab2948979327dbcbdb1f78b5 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_7a5a5d80ab2948979327dbcbdb1f78b5(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
